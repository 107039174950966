@keyframes scaleIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes popup {
  0% {
    opacity: 0;
    transform: translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes clipAnim {
  0% {
    clip-path: circle(0%);
  }
  50% {
    clip-path: circle(100%);
  }
}
@keyframes slideFromRight {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes scaleOpac {
  from {
    opacity: 0;
    transform: scale(0.9);
    transform-origin: left bottom;
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@mixin customPopupOffset($offset, $duration, $delay: 0s) {
  @keyframes popupC {
    from {
      opacity: 0;
      transform: translateY($offset);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  animation: popupC $duration $delay;
}
