.aizo-settings {
  display: grid;
  grid-template-columns: 250px 1fr;
  height: 100%;
  &-options {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
  }
  &-route-item {
    position: relative;
    padding: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    &:not(&--active):hover {
      background-color: rgba($color: #000000, $alpha: 0.03);
    }
    > svg {
      margin: 0 1rem;
    }
    .indicator {
      display: none;
      position: absolute;
      right: 1rem;
      color: #fff;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
    }
    &--active {
      background-color: $color-primary;
      color: #fff;
      transition: 0.1s;
      .indicator {
        display: flex;
      }
    }
  }
  &-panels {
    background-color: #fafafa;
    padding: 2rem;
    &-title{
        font-weight: bold;
        font-size: 1.5rem;
    }
  }
}
