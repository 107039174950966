$product-image-width: 10rem;

.catalogue-main {
  height: 100%;
  display: grid;
  grid-template-columns: 500px 2fr;
  column-gap: 2px;
  overflow: hidden;
}

.ov-load {
  @include flex(column);
  position: absolute;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff85;
  font-weight: bold;
  span {
    display: block;
    margin: 1rem 0;
  }
}

.cat-panel {
  position: relative;
  background-color: #fafafa;
  height: 91vh;
  overflow-y: auto;
  @include scroll-bar-sm();
  .MuiButton-root {
    border-radius: 2rem;
  }
  &-header {
    @include flex();
    justify-content: space-between;
    padding: 1rem 2rem;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
    > div {
      @include flex();
      font-weight: 500;
      font-size: 1.3rem;
      color: #000;
    }

    .MuiButton-root {
      color: $color-primary;
      border-color: $color-primary;
      // background-color: $color-primary;
    }
    &-search {
      @include flex();
      position: relative;
      // margin: 0 1rem;
      svg {
        position: absolute;
        left: 13px;
        font-size: 1.5rem;
        color: #000;
      }
      input {
        border: none;
        border-radius: 2rem;
        outline: none;
        padding: 10px;
        padding-left: 3rem;
        font-weight: 400;
        font-size: 1rem;
        // background-color: #00000010;
        transition: 0.2s;
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
        &:hover {
          background-color: #00000012;
        }
        &:focus {
          background-color: #fff;
          color: #000;
          ~ svg {
            color: #000;
          }
          &::placeholder {
            color: #00000086;
          }
        }
        // &::placeholder {
        //   color: #ffffff86;
        // }
      }
    }
  }
  &-header-sec {
    @include flex();
    justify-content: space-between;
    background-color: #fff;
    padding: 1rem;
    display: none;
  }
  &-item {
    animation: popup 0.2s;
    box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
    // border-radius: 5px;
    overflow: hidden;
    &-main {
      @include flex();
      background-color: #fff;
      justify-content: space-between;
      padding: 1rem 1.3rem;
      min-height: 4.5rem;
      box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
      cursor: pointer;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

      .move-icon {
        display: none;
        position: absolute;
        left: 10px;
        min-height: 1.2rem;
        width: 1rem;
        opacity: 0.3;
        &:hover {
          cursor: grab;
          opacity: 1;
          // color: #fff;
        }
      }

      &-left {
        font-weight: 500;
        margin-left: 1rem;
        &-caret {
          font-size: 1.2rem;
          transition: transform 0.3s;
          position: absolute;
          right: 1rem;
        }
      }
      &-right {
        // display: none;
        position: absolute;
        right: 3rem;
        opacity: 0;
        transition: 0.2s;
      }
      &:hover & {
        // color: $color-primary;
        &-right {
          opacity: 1;
        }
      }
      &:hover {
        .move-icon {
          display: block;
        }
      }
    }
    &-body {
      background-color: #fff;
      padding: 1rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    }
  }
  &-product {
    cursor: pointer;
    border: 1px solid transparent;
    @include customPopupOffset(10px, 0.1s);
    margin-bottom: 1px;
    &:hover {
      border: 1px solid #2196ff;
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      .cat-panel-product-right {
        display: block;
      }
    }
    & > div {
      @include flex();
      justify-content: space-between;
      padding: 10px;
    }
    &-add {
      @include flex();
      margin: 10px 0;
    }
    &-left {
      @include flex();
      img {
        width: 3rem;
        height: 3rem;
        object-fit: cover;
      }
      .food-tag {
        svg {
          border-width: 1px;
          padding: 2px;
        }
      }
      & > :not(.food-tag) {
        margin-right: 1rem;
        font-weight: 500;
      }
    }
    // &-right{

    // }
    &--selected {
      border: 1px solid #2196ff;
      background-color: #2196ff34;
      z-index: 20;
    }
  }
  &-cat-add {
    @include flex();
    margin: 1rem 0;
  }
}
.product-details {
  height: 91.2vh;
  overflow-y: auto;
  background-color: #fafafa;
  @include scroll-bar-sm();
  * {
    box-sizing: border-box;
  }
  &-main-details {
    max-width: 650px;
    margin-top: 2rem;
    opacity: 0;
    @include customPopupOffset(10px, 0.3s, 0.2s);
    animation-fill-mode: forwards;
    margin: auto;
  }
  &-bottom {
    @include flex();
    justify-content: space-between;
    font-weight: bold;
    padding: 1rem;
    background-color: #fff;
    position: sticky;
    bottom: 0;
    box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1),
      0 1rem 2rem rgba($color: #000000, $alpha: 0.2);
    border-radius: 5px 5px 0 0;
    max-width: 650px;
    margin: 0 auto;
    margin-top: 3rem;
    &-left {
      @include customPopupOffset(10px, 0.5s);
      span {
        color: #ffffffa9;
        font-weight: 400;
        margin-left: 1rem;
        svg {
          font-size: 1.5rem;
        }
      }
    }
    .MuiButton-root {
      margin-left: 1rem;
      color: $color-danger;
      border-color: $color-danger;
      &:last-child {
        background-color: #fff;
        color: $color-primary;
        font-weight: bold;
        &:disabled {
          background-color: transparent;
          color: #ddd;
        }
      }
    }
  }
  &-head {
    @include flex();
    justify-content: flex-start;
    align-items: flex-start;
    margin: 2rem auto;
    max-width: 650px;
    @include customPopupOffset(5px, 0.1s);
    &-image {
      margin-right: 1.5rem;
      img {
        width: $product-image-width;
        height: $product-image-width;
        box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.2);
        border-radius: 10px;
        object-fit: cover;
      }
      & > div {
        @include flex(column);
        width: $product-image-width;
        height: $product-image-width;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        .add-icon {
          font-size: 3rem;
          margin: 5px 0;
          border-radius: 50%;
        }
      }
      &-p {
        position: relative;
        &:hover {
          > div {
            opacity: 1;
          }
        }
        > div {
          @include flex(column);
          padding: 1rem;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          background-color: #ffffffd6;
          border-radius: 10px;
          opacity: 0;
          transition: 0.3s;
        }
      }
      &-a {
        // border: 1px solid rgba($color: #000000, $alpha: 0.2);
        padding: 1rem 2rem;
        background-color: rgba($color: #000000, $alpha: 0.05);
        text-align: center;
        &:hover {
          font-weight: bold;
          .add-icon {
            color: $color-primary;
          }
        }
      }
    }
    &-inputs {
      @include flex();
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      .food-tag {
        margin-top: 1rem;
        svg {
          font-size: 1.5rem;
          padding: 3px;
        }
      }
      i {
        font-weight: 500;
        margin: 10px 0;
        font-style: normal;
        background-color: #eee;
        color: transparent;
        border-radius: 2rem;
        margin-right: 10px;
        padding: 0 1rem;
      }
      & > div {
        @include flex(column);
        align-items: flex-start;
        justify-content: flex-start;
        &:last-child {
          width: 100%;
        }
      }
      &-name {
        font-size: 2rem;
        font-weight: bold;
      }
      &-price {
        font-family: 'Roboto';
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 1rem;
        i {
          padding: 0 2rem;
        }
      }
      &-desc {
        @include scroll-bar-sm();
        white-space: pre-line;
        max-height: 5rem;
        font-family: 'Poppins';
        font-size: 1rem;
        i {
          font-size: 0.8rem;
        }
      }
    }
  }
  &-section {
    // margin: 1rem 0;
    &-item {
      padding: 2rem;
      background-color: #fff;
      // border-radius: 3px;
      min-height: 20vh;
      // box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1);
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      // 0 2px 5px rgba($color: #000000, $alpha: 0.1);
      &-head {
        position: relative;
        @include flex();
        padding-top: 5px;
        justify-content: space-between;
        font-weight: bold;
        font-size: 1.4rem;
        &-title {
          @include flex();
          // font-weight: 500;
          svg {
            display: none;
            margin-right: 1rem;
          }
        }
        &-other {
          position: absolute;
          right: -10px;
          // top: 0;
        }
        // &-inputs{
        //   &-desc{
        //     max-width: 90%;
        //     white-space: pre-line;
        //   }
        // }
      }
      &-body {
        label {
          color: #000;
          font-weight: 500;
          margin: 5px 0;
          margin-top: 1rem;
        }
        .req{
          &::after{
            content: '*';
            color: red;
            margin-left: 3px;
            font-size: 1.2rem;
          }
        }
        input {
          padding: 0.7rem;
          width: 100%;
          border: none;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
          border-radius: 3px;
        }
        textarea {
          width: 100%;
          // max-width: 100%;
          // min-width: fit-content;
          height: 5rem;
          max-height: 10rem;
          padding: 10px;
          border: none;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
          border-radius: 3px;
          @include scroll-bar-sm();
        }
      }
    }
  }

  &-details-box {
    // @include flex(column);
    align-items: flex-start;
    margin: 1rem 0;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    label {
      margin: 0;
    }
    &-type {
      > div {
        display: inline-block;
        text-align: center;
        padding: 10px;
        min-width: 5rem;
        margin-right: 1rem;
        border-radius: 5px;
        font-weight: 500;
        transition: 0.2s;
        cursor: pointer;
        svg {
          margin-left: 5px;
          font-size: 1.3rem;
        }
      }
      &--active {
        color: #fff;
        box-shadow: none;
        background-color: $color-primary;
        svg {
          display: inline-block;
        }
      }
      &--inactive {
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
        svg {
          display: none;
          color: #000;
        }
      }
    }
  }
  &-pricing-box {
    @include flex();
    margin: 10px 0;
    > div {
      position: relative;
      margin: 0 10px;
      span {
        position: absolute;
        top: 60%;
        left: 10px;
        font-weight: bold;
        font-family: 'Roboto';
        font-size: 1.3rem;
      }
      input {
        padding-left: 2.3rem;
      }
    }
  }
  &-images-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 1rem;
    margin: 1rem 0;
    margin-top: 2rem;
    &-item {
      position: relative;
      &:hover {
        .product-details-images-box-item-mask {
          opacity: 1;
        }
      }
      &-primary {
        position: absolute;
        background-color: $color-primary;
        color: #fff;
        top: 5px;
        left: -3px;
        border-radius: 3px;
        padding: 2px 10px;
        z-index: 15;
        @include customPopupOffset(-10px, 0.2s);
        box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.5);
      }
      img {
        object-fit: cover;
        width: 100%;
        height: $product-image-width * 0.8;
        // border-radius: 10px;
      }
      &-ttip {
        font-size: 1rem;
        background-color: #fff;
      }
      &-mask {
        @include flex();
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        background-color: #000000a6;
        border-radius: 10px;
        transition: opacity 0.2s;
        opacity: 0;
        .MuiIconButton-root {
          color: #fff;
        }
      }

      &-add {
        @include flex();
        cursor: pointer;
        background-color: #fafafa;
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
        min-height: $product-image-width * 0.8;
        // border-radius: 10px;
        &:hover {
          background-color: #eee;
        }
        svg {
          font-size: 3rem;
        }
      }
    }
  }
  &-customization-box {
    padding-top: 2rem;
    &-choice {
      @include flex();
      box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
      // overflow: hidden;
      & > span {
        display: none;
        margin: 0 1rem;
        font-weight: bold;
      }
      & > div {
        flex: 1;
        @include flex();
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1rem;
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
        cursor: pointer;
        transition: 0.2s;
        // margin: 0 10px;
        &:hover {
          background-color: $color-primary;
          color: #fff;
          box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.1);
          svg {
            color: #fff;
          }
          span {
            color: #ffffff96;
          }
        }
        > svg {
          font-size: 2rem;
          margin-right: 1rem;
          // color: #fff;
          border-radius: 50%;
          // background-color: $color-primary;
          padding: 2px;
        }
        & > div {
          @include flex(column);
          align-items: flex-start;
          font-size: 1.3rem;
          font-weight: 500;
          font-weight: bold;
          span {
            font-size: 1rem;
            font-weight: normal;
            color: #aaa;
          }
        }
      }
    }
    &-variant {
      &-form {
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
        margin: 1px 0;
        &-head {
          @include flex();
          justify-content: space-between;
          padding: 1rem;
          box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.05);
          background-color: #fff;
          font-weight: bold;
          cursor: pointer;
          position: sticky;
          top: 0;
          z-index: 10;
          i {
            font-weight: 400;
            font-size: 1rem;
          }
          &:hover {
            > div:last-child {
              .del-btn {
                display: block;
              }
            }
          }
          .badge {
            margin: 0 1rem;
            background-color: transparent;
            color: $color-primary;
            box-shadow: 0 0 0 1px $color-primary;
          }
          > span {
            svg {
              margin-right: 1rem;
            }
          }
          > div {
            &:last-child {
              @include flex();
              position: absolute;
              right: 1rem;
              .del-btn {
                display: none;
              }
            }
          }
        }
        &-body {
          padding: 1rem;
          background-color: #fafafa;
          > span {
            font-weight: 500;
          }
          &-bottom {
            @include flex();
            justify-content: space-between;
            .MuiButton-root {
              background-color: $color-primary;
              color: #fff;
            }
          }
        }
        // &-inputs {

        // }
        &-options {
          background-color: #fff;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
          border-radius: 3px;
          margin: 1rem 0;
          & > div:last-child {
            @include flex();
            justify-content: flex-end;
            padding: 10px;
            .MuiButton-root {
              color: $color-primary;
            }
          }
        }
      }
      &-option {
        padding: 1rem;
        &:not(:last-child) {
          border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        }
        &-list {
          @include flex();
          justify-content: flex-start;
          flex-wrap: wrap;
          .a-g-item {
            @include flex();
            box-shadow: 0 0 0 1px $color-primary;
            border-radius: 2px;
            margin-right: 1rem;
            margin-top: 10px;
            @include customPopupOffset(10px, 0.2s);
            &-name {
              padding: 5px 10px;
              color: $color-primary;
            }
            svg {
              width: 2rem;
              padding: 5px;
              height: 100%;
              color: $color-primary;
              &:hover {
                background-color: #{$color-primary}23;
                cursor: pointer;
              }
            }
          }
          .MuiButton-root {
            margin-top: 10px;
            margin-right: 10px;
          }
        }

        & > div {
          &:first-child {
            font-weight: 500;
            font-size: 0.9rem;
            margin-bottom: 5px;
          }
        }
        &-inputs {
          display: grid;
          grid-template-columns: 2fr 1fr auto;
          column-gap: 10px;
        }
        &-popper {
          @include flex(column);
          background-color: #fff;
          box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.1);
          border-radius: 5px;
          overflow: hidden;
          border: 1px solid rgba($color: #000000, $alpha: 0.2);
          margin-top: 5px;
          margin-left: 10px;
          > div {
            &:first-child {
              @include flex(column);
              align-items: flex-start;
              justify-content: flex-start;
              padding: 10px;
              max-height: 15rem;
              overflow-y: auto;
              @include scroll-bar-sm();
              .add-on-group {
                padding: 10px;
                margin: 0;
                width: 100%;
                cursor: pointer;
                margin: 2px 0;
                border-radius: 5px;
                padding-right: 1rem;
                svg {
                  margin-right: 5px;
                }
                &--unChecked {
                  &:hover {
                    background-color: rgba($color: #000000, $alpha: 0.05);
                  }
                }
                &--checked {
                  background-color: $color-primary;
                  color: #fff;
                }
              }
            }
            &:last-child {
              @include flex();
              justify-content: flex-end;
              border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
              width: 100%;
              padding: 5px;
            }
          }
        }
      }
      &-bottom {
        padding: 10px;
      }
    }
  }
}
.food-tag {
  @include flex();
  margin-right: 10px;
  svg {
    border: 2px solid rgba($color: #000000, $alpha: 0.1);
    border-radius: 3px;
  }
  &--veg {
    svg {
      border-color: green;
      color: green;
    }
  }
  &--non-veg {
    svg {
      border-color: #d50000;
      color: #d50000;
    }
  }
  svg {
    padding: 5px;
  }
}

.menu-data-manager {
  label {
    font-weight: 500;
    color: #888;
    margin: 0;
    span {
      font-size: 0.9rem;
      color: #aaa;
    }
  }
  input {
    border: none;
    padding: 0.6rem;
    box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
    border-radius: 3px;
  }
  &-tabs {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  }
  .error-marker {
    position: absolute;
    right: 5px;
    top: 50%;
    svg {
      color: red;
      font-size: 1.3rem;
    }
  }
  &-main {
    background-color: #fff;
    input {
      width: 100%;
      background-color: #fafafa;
    }
    label {
      margin: 0;
    }
    &-add-ons {
      display: grid;
      grid-template-columns: 3fr 2fr;
      &-head {
        @include flex();
        justify-content: space-between;
        padding: 1rem 5%;
        position: sticky;
        top: 0;
        margin-top: 1rem;
        z-index: 10;
        h5 {
          font-weight: bold;
        }
      }
      .inputs-cont {
        @include flex(column);
        align-items: flex-start;
        input {
          width: 100%;
        }
      }
      .add-ons {
        &--mode-create {
          @include customPopupOffset(5px, 0.2s);
          border: 1px solid rgba($color: #000000, $alpha: 0.1);
          border-radius: 5px;
          padding: 2rem;
          box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.05);
          background-color: #fff;
          & > div {
            &:first-child {
              font-weight: bold;
              font-size: 1.2rem;
            }
            &:nth-child(2) {
              margin: 2rem 0;
            }
            &:last-child {
              @include flex();
              justify-content: flex-end;
              .MuiButton-root {
                margin-left: 1rem;
                &:last-child {
                  background-color: $color-primary;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .add-on-item {
        @include flex();
        border-radius: 3rem;
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
        margin: 0 10px 10px 0;
        overflow: hidden;
        span {
          &:first-child {
            font-weight: bold;
            margin: 5px 10px;
          }
          &:last-child {
            height: 100%;
            font-size: 1.4rem;
            padding: 0 1rem;
            color: #999;
            &:hover {
              background-color: #eee;
              cursor: pointer;
              color: $color-danger;
            }
          }
        }
      }
      &-groups {
        @include flex(column);
        justify-content: flex-start;
        overflow-y: auto;
        height: 84vh;
        @include scroll-bar-sm(#aaa);
        .menu-data-manager-main-add-ons-head {
          width: 80%;
        }
        &-list {
          width: 70%;
          .add-ons {
            &--mode-create {
              & > div {
                &:nth-child(2) {
                  & > div {
                    & > div {
                      position: relative;
                    }
                    .add-ons-inputs {
                      display: grid;
                      grid-template-columns: 2fr 1fr 1fr;
                      column-gap: 1rem;
                      margin-bottom: 10px;
                      > div {
                        position: relative;
                      }
                    }
                    &:nth-child(2) {
                      padding: 2rem 1rem;
                      text-align: center;
                      border-radius: 3px;
                      margin-top: 1rem;
                      margin-bottom: 10px;
                      transition: 0.4s;
                      border: 2px dotted $color-primary;
                      color: $color-primary;
                      font-weight: bold;
                    }
                    &:last-child {
                      @include flex();
                      justify-content: flex-start;
                      flex-wrap: wrap;
                    }
                  }
                }
              }
            }
          }
          &-item {
            @include customPopupOffset(10px, 0.2s);
            border-radius: 5px;
            overflow: hidden;
            margin: 1rem 0;
            box-shadow: 0 2px 5px rgba($color: #000000, $alpha: 0.1),
              0 0 0 1px rgba($color: #000000, $alpha: 0.1);
            .head {
              padding: 1rem;
              background-color: #fff;
              box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
              cursor: pointer;
              .badge {
                box-shadow: 0 0 0 1px $color-primary;
                color: $color-primary;
                margin: 0 1rem;
                font-weight: bold;
              }
            }
            .item-body {
              padding: 1rem 2rem;
              &-inputs {
                > div {
                  &:first-child {
                    display: grid;
                    grid-template-columns: 2fr 1fr 1fr;
                    column-gap: 1rem;
                    margin-bottom: 10px;
                  }
                }
              }
              &-drop-zone {
                > div {
                  @include flex();
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  &:first-child {
                    padding: 2rem;
                    margin: 10px 0;
                    margin-top: 1rem;
                    justify-content: center;
                    border: 2px dotted $color-primary;
                    color: $color-primary;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
      &-items {
        @include flex(column);
        justify-content: flex-start;
        align-items: flex-start;
        overflow-y: auto;
        height: 84vh;
        padding-bottom: 10px;
        @include scroll-bar-sm();
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
        background-color: #fafafa;
        .menu-data-manager-main-add-ons-head {
          width: 100%;
        }
        &-list {
          width: 100%;
          .add-ons {
            &--mode-create {
              margin: 1rem 2rem;
              & > div {
                &:nth-child(2) {
                  > div {
                    position: relative;
                    &:first-child {
                      display: grid;
                      grid-template-columns: 2fr 1fr;
                      column-gap: 1rem;
                      margin-bottom: 1.5rem;
                      > div {
                        position: relative;
                      }
                    }
                  }
                }
              }
            }
          }
          &-item {
            overflow: hidden;
            box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1),
              0 2px 5px rgba($color: #000000, $alpha: 0.05);
            border-radius: 2px;
            margin: 10px 2rem;
            .head {
              position: relative;
              @include flex();
              justify-content: space-between;
              padding: 1rem;
              background-color: #fff;
              box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
              cursor: pointer;
              font-weight: 500;
              &-left {
                p {
                  margin: 0;
                }
              }
              &-right {
                position: absolute;
                right: 0;
              }
              &:hover {
                background-color: $color-primary;
                color: #fff;
                svg {
                  color: #fff;
                }
              }
            }
            .item-body {
              background-color: #fff;
              padding: 1.5rem 2rem;
              border: 1px solid transparent;
              border-color: rgba($color: #000000, $alpha: 0.1) transparent;
              column-gap: 10px;
              > div {
                &:first-child {
                  display: grid;
                  grid-template-columns: 2fr 1fr;
                  column-gap: 1rem;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
