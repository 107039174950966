.a-splash-screen {
  display: grid;
  place-items: center;
  height: 100%;

  .a-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      animation: popup 0.8s alternate infinite forwards;
      opacity: 0;
      display: inline-block;
      width: 0.6rem;
      height: 0.6rem;
      background-color: $color-primary;
      border-radius: 50%;
      margin: 0 3px;
    }
  }
}

.text-editor{
  box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
  &-toolbar{
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
  }
  // &-input{
  //   padding: 1rem;
  // }
}
.public-DraftEditor-content{
  padding: 1rem;
  // word-wrap: break-word;
}