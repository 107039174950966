@mixin flex($direction: row, $reverse: false) {
  display: flex;
  @if $reverse==true {
    flex-direction: '#{$direction}-reverse';
  } @else {
    flex-direction: $direction;
  }

  justify-content: center;
  align-items: center;
}

@mixin scroll-bar-sm($color: #000, $width: 3px) {
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color;
  }
  &::-webkit-scrollbar-track {
    background-color: #eee;
  }
}
@mixin darkBack($alpha) {
  background-color: rgba($color: #000, $alpha: $alpha);
}
@mixin lightBack($alpha) {
  background-color: rgba($color: #fff, $alpha: $alpha);
}
