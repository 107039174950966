.get-started-main {
  min-height: 100vh;
  font-family: 'Roboto';
  animation: fadeIn 1s;
  .g-s-left {
    @include flex(column);
    justify-content: flex-start;
    height: 100%;
    // box-shadow: 2px 0 5px rgba($color: #000000, $alpha: 0.1);
    z-index: 10;
    background-color: #fff;
    padding: 2rem;
    &-logo {
      font-weight: bold;
      font-size: 2rem;
      img {
        width: 15rem;
        margin-top: 2rem;
      }
    }
    &-accordion {
      flex: 1;
      margin-top: 10rem;
    }
    &-bottom {
      @include flex();
      // font-weight: bold;
      font-size: 1.2rem;
      color: #555;
      svg {
        margin: 0 5px;
        font-size: 1.5rem;
      }
      span {
        // font-weight: bold;
        cursor: pointer;
        margin: 0 5px;
        color: $color-primary;
        &:hover {
          font-weight: bold;
        }
      }
    }
    .g-s-step {
      @include flex(column);
      align-items: flex-start;
      margin: 3rem 0;
      opacity: 0;
      animation: slideFromRight 0.5s forwards;
      &-top {
        position: relative;
        @include flex();
        &-title {
          // text-transform: uppercase;
          font-size: 1.3rem;
          transition: 0.2s;
          font-family: 'Poppins';
          &--active {
            color: $color-primary;
            font-weight: 500;
          }
          &--inactive {
            color: #999;
          }
          &--done {
            color: #999;
          }
        }
        &-icon {
          position: absolute;
          left: -5rem;
          @include flex();
          transition: 0.2s;
          width: 2.7rem;
          height: 2.7rem;
          margin: 0 1rem;
          padding: 5px;
          border-radius: 50%;
          font-size: 1.3rem;
          &--active {
            color: $color-primary;
            background-color: #fff;
            box-shadow: 0 0 0 2px rgba($color: $color-primary, $alpha: 1);
            font-weight: bold;
          }
          &--inactive {
            background-color: #eee;
          }
          &--done {
            color: $color-primary;
            // background-color: #fff;
          }
        }
        .arrow-icon {
          position: absolute;
          right: -2.3rem;
          font-size: 1.5rem;
          color: $color-primary;
          animation: slideFromRight 0.2s;
        }
      }
      &-body {
        color: #888;
        width: 12rem;
      }
    }
  }
  .g-s-right {
    position: relative;
    @include flex(column);
    justify-content: start;
    background-color: #fafafa;
    height: 100%;
    padding: 1rem;
    font-family: 'Poppins';
    &-main {
      min-width: 90vh;
      margin-top: 5%;
      &-component {
        animation: fadeIn 0.4s;
        &-heading {
          font-size: 2rem;
          font-weight: bold;
          animation: popup 0.3s;
        }
        &-sub-heading {
          opacity: 0;
          font-size: 1.2rem;
          color: #000;
          animation: popup 0.3s 0.1s forwards;
          // margin: 1rem 0;
          margin-bottom: 10%;
        }
        // &-input-cont {
        //   margin-top: 5%;
        // }
        &-input {
          @include flex(column);
          align-items: flex-start;
          label {
            // font-weight: bold;
            b {
              color: $color-primary;
              font-weight: bold;
              margin: 0 5px;
            }
          }
          > div {
            width: 100%;
          }
          input {
            // border: 1px solid rgba($color: #000000, $alpha: 0.4);
            border: none;
            // background-color: #fff;
            box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
            padding: 0.8rem;
            width: 100%;
            border-radius: 5px;
            font-size: 1.2rem;
          }
          .MuiButton-root:not(:first-child) {
            width: 100%;
          }
        }
      }
    }
    &-bottom {
      @include flex();
      justify-content: space-between;
      position: absolute;
      bottom: 5%;
      left: 10%;
      right: 10%;
      .MuiButton-root:not(:first-child) {
        background-color: $color-primary;
      }
    }
  }
}
