.custom-modal {
  min-height: 40vh;
  min-width: 60vh;
  .MuiDialogTitle-root {
    border-bottom: 1px solid #00000032;
    // background-color: $color-primary;
    // color: #fff;
    svg {
      //   background-color: #eee;
      color: #000;
      border-radius: 50%;
      // padding: 1rem;
      font-size: 2rem;
      margin-right: 1rem;
    }
  }
  .MuiDialogContent-root {
    @include flex(column);
    align-items: flex-start;
    // background-color: #fafafa;
  }
  .MuiDialogActions-root {
    padding: 1rem;
    background-color: #fafafa;
    .MuiButton-root:nth-child(2) {
      background-color: $color-primary;
    }
  }

  input {
    padding: 1rem;
    border-radius: 5px;
    border: none;
    box-shadow: 0 0 0 1px #00000056;
  }
  &-mask {
    @include darkBack(0.5);
  }

  &-create-cat {
    width: 100%;
    > div {
      > label {
        // font-weight: 500;
        font-size: 1.2rem;
        margin-top: 1rem;
      }
    }

    &-main {
      @include flex(column);
      align-items: flex-start;

      input {
        width: 100%;
      }
    }
    &-inputs {
      align-items: flex-start;
      .MuiTypography-body1 {
        font-size: 1.2rem;
      }
    }
  }
}
