@mixin svg-hover() {
  box-sizing: content-box;
  padding: 10px;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 50%;
  overflow: visible;
  &:hover {
    background-color: #eee;
  }
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.a-o-wrapper {
  display: grid;
  grid-template-columns: 450px 1fr;
  height: 91vh;
  * {
    box-sizing: border-box;
  }
  .o-l {
    background-color: #fff;
    height: 100%;
    border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
    // padding: 1rem;
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      .o-search {
        position: relative;
        svg {
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
        }
        input {
          font-size: 1rem;
          padding: 3px 5px 3px 2rem;
        }
      }
      .o-add {
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 5px;
        align-items: center;
        margin: 0 10px;
        svg {
          font-size: 1.5rem;
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      .o-item {
        position: relative;
        animation: scaleOpac 0.2s;
        display: grid;
        grid-template-columns: auto 1fr;
        column-gap: 1rem;
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
        border-radius: 2px;
        padding: 1rem;
        padding-bottom: 1.5rem;
        margin-bottom: 1rem;
        transition: 0.1s;
        cursor: pointer;
        .o-initials {
          display: grid;
          place-items: center;
          width: 3rem;
          height: 3rem;
          background-color: $color-primary;
          color: #fff;
          font-size: 2rem;
          border-radius: 50%;
        }
        .o-stat {
          position: absolute;
          right: 10px;
          bottom: 10px;
        }
        &-head {
          display: flex;
          flex-direction: column;
        }
        &-desc {
          display: flex;
          flex-direction: column;
          p {
            font: 500 1rem 'Poppins';
            margin: 0;
            color: #000;
          }
          & > span {
            color: #666;
          }
        }
        &:hover {
          box-shadow: 0 0 0 1px $color-primary,
            0 1rem 10px rgba($color: #000000, $alpha: 0.1);
        }
      }
      .selected {
        cursor: default;
        background-color: #{$color-primary}24;
        border-radius: 5px;
        box-shadow: 0 0 0 1px $color-primary;
        // .o-initials {
        //   background-color: #ffffff39;
        // }
        // .o-item-desc {
        //   p {
        //     color: #fff;
        //   }
        //   span {
        //     color: #ffffffa9;
        //   }
        // }
        &:hover {
          box-shadow: 0 0 0 1px $color-primary;
        }
      }
    }
  }
  .o-d-panel {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    height: 91vh;
    overflow-y: auto;
    padding: 2rem;
    img {
      max-height: 50rem;
      max-width: 50rem;
    }
    .o-det {
      animation: fadeIn 0.2s;
      display: grid;
      row-gap: 1rem;
      width: 90%;
      max-width: 50rem;
      p {
        margin: 0;
      }
      .d-heading {
        // position: sticky;
        // top: 0;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        p {
          font: bold 1.5rem 'Poppins';
        }
        svg {
          @include svg-hover();
          font-size: 1.3rem;
        }
        .selected {
          color: $color-primary;
        }
      }
      &-head {
        display: grid;
        grid-template-columns: 16rem 1fr;
        column-gap: 2rem;
        .o-img {
          img {
            width: 100%;
            min-height: 12rem;
            box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
          }
        }

        .o-details {
          position: relative;
          p {
            font: 500 1.5rem 'Poppins';
          }
          .i-desc {
            display: grid;
            grid-template-columns: auto 1fr;
            column-gap: 5px;
            margin-top: 1rem;
            svg {
              color: $color-primary;
              font-size: 1.2rem;
            }
          }
          .det-edit {
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
      &-body {
        .det-grid {
          width: 100%;
        }
        .v-list {
          border-radius: 5px;
          overflow: hidden;
          box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
          padding: 1rem 0;
          transition: 0.2s;
          & > div {
            display: grid;
            grid-template-columns: 1fr auto;
            align-items: center;
            cursor: pointer;
            padding: 1rem 2rem;
            div {
              display: flex;
              flex-direction: row;
              p {
                font: 500 1.2rem 'Poppins';
                span {
                  display: block;
                  font-size: 0.9rem;
                  font-weight: 100;
                }
              }
              svg {
                font-size: 1.5rem;
                margin-right: 2rem;
              }
            }
            &:hover {
              background-color: #fafafa;
            }
          }
        }
        .v-grid {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
          gap: 1rem;
          .d-arr {
            display: none;
          }
          & > div {
            cursor: pointer;
            padding: 2rem;
            box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
            border-radius: 5px;
            transition: 0.2s;
            div {
              display: grid;
              place-items: center;
              svg {
                font-size: 2.5rem;
              }
              p {
                text-align: center;
                margin-top: 1rem;
                font: bold 1.2rem 'Poppins';
                span {
                  display: block;
                  font: 400 0.9rem 'Poppins';
                }
              }
            }
            &:hover {
              svg {
                color: $color-primary;
              }
              box-shadow: 0 0 0 1px $color-primary;
            }
          }
        }
      }
      .cust-list {
        & > div {
          display: grid;
          grid-template-columns: auto 1fr;
          column-gap: 2rem;
          margin-bottom: 1rem;
          padding: 1rem;
          img {
            width: 4rem;
            border-radius: 50%;
            box-shadow: 0 5px 5px rgba($color: #000000, $alpha: 0.2);
          }
          div {
            p {
              font: bold 1rem 'Poppins';
              span {
                display: block;
                font: 100 0.8rem 'Poppins';
                margin: -2px 0 5px 0;
              }
            }
          }
          &:hover {
            background-color: #fafafa;
          }
        }
      }
    }
    .action-page {
      animation: fadeIn 0.2s;
      position: absolute;
      z-index: 10;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: #fff;
      &-head {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2rem;
        svg {
          @include svg-hover();
          font-size: 1.3rem;
        }
        p {
          font: bold 1.5rem 'Poppins';
          margin: 0 1rem;
        }
      }
      &-body {
        width: 90%;
        max-width: 600px;
        margin: 0 auto;
        .o-tab {
          position: sticky;
          top: -2rem;
          background-color: #fff;
          z-index: 10;
        }
        .load-mask {
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          background-color: #ffffffc5;
          display: flex;
          justify-content: center;
          padding: 5rem 0;
        }
        .o-stock {
          ul {
            position: relative;
            box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
            margin: 2rem 0;
            padding: 1rem 0;
            overflow: hidden;
            border-radius: 5px;
            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px 1.5rem;
              border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
              & > div {
                &:first-child {
                  p {
                    display: inline-block;
                    margin: 0;
                    font: 500 1rem 'Poppins';
                  }
                  span {
                    display: inline-block;
                    color: $color-primary;
                    box-shadow: 0 0 0 1px $color-primary;
                    margin-left: 1rem;
                    padding: 0 5px;
                    border-radius: 1rem;
                    font-size: 0.8rem;
                    font-weight: bold;
                  }
                }
              }
              &:hover {
                background-color: #fafafa;
              }
            }
            .freeze {
              background-color: #eee;
              cursor: default;
              &:hover {
                background-color: #eee;
              }
            }
          }
        }
        .o-timing {
          p {
            position: sticky;
            top: -2.2rem;
            padding: 2rem 0 1rem 0;
            background-color: #fff;
            z-index: 10;
            font: 500 1.5rem 'Poppins';
            box-shadow: 0 0 0 1px #fff;
            svg {
              font-size: 1.5rem;
              margin-right: 5px;
            }
          }
          ul {
            padding: 10px 0;
            box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
            li {
              animation: popup 0.2s;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding: 2px 1rem;
              & > div:first-child {
                display: grid;
                grid-template-columns: 1fr auto 1fr;
                column-gap: 1rem;
                label {
                  display: inline-block;
                  margin-right: 10px;
                  color: $color-primary;
                }
                input {
                  border: none;
                }
              }
            }
          }
          .add-slot {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 1rem 0 2rem 0;
            &::after {
              position: absolute;
              content: '';
              width: 100%;
              border-top: 1px solid #aaa;
              z-index: -1;
            }
          }
          &-bottom {
            display: flex;
            justify-content: flex-end;
            margin: 4rem 0;
          }
        }
        .o-reciept {
          margin: 2rem 0;
          &-top {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 2rem;
            & > span {
              font: 500 1.1rem 'Poppins';
            }
            input,
            textarea {
              padding: 10px;
              font-size: 1rem;
            }
            textarea {
              min-height: 5rem;
              max-height: 14rem;
            }
          }
          &-bottom {
            float: right;
            clear: both;
            margin: 3rem 0;
          }
        }
      }
    }
  }
}
.location-page {
  display: grid;
  grid-template-columns: 500px 1fr;
  height: 91vh;
  .l-list {
    background-color: #fff;
    box-shadow: 10px 0 1rem rgba($color: #000000, $alpha: 0.1);
    z-index: 10;
    // overflow-y: auto;
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      padding: 1.5rem 2rem;
      font-size: 1.3rem;
    }
    .city-edit {
      // margin: 1rem;
      padding: 1.5rem;
      // box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
      border-radius: 5px;
      .c-inp {
        // display: grid;
        // grid-template-columns: 5rem 1fr;
        // column-gap: 1rem;
        margin-bottom: 2rem;
        align-items: center;
        &-list {
          position: relative;
          ul {
            position: absolute;
            top: 100%;
            z-index: 10;
            background-color: #fff;
            max-height: 20rem;
            box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1),
              0 5px 1rem rgba($color: #000000, $alpha: 0.1);
            width: 100%;
            height: auto;
            padding: 0;
            li {
              box-shadow: none;
              padding: 10px 1rem;
              cursor: pointer;
              &:hover {
                background-color: rgba($color: #000000, $alpha: 0.01);
              }
            }
          }
        }
        span {
          display: block;
          margin: 5px 0;
        }
        input {
          font-size: 1rem;
          padding: 10px;
          width: 100%;
        }
      }
      .c-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }
    ul {
      @include scroll-bar-sm();
      height: 81vh;
      overflow-y: auto;
      padding: 1rem;
      li {
        // margin-bottom: 10px;
        box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
        .st-head {
          position: sticky;
          top: -1.1rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          background-color: #fff;
          padding: 1rem;
          font-size: 1.2rem;
          box-shadow: 0 1px 0 0 rgba($color: #000000, $alpha: 0.1);
          & > div {
            svg {
              margin-right: 1rem;
            }
          }
          &:hover {
            background-color: #f6f6f6;
          }
        }
        .localities-list {
          background-color: #fafafa;
          padding: 1rem;
          // display: grid;
          span {
            cursor: pointer;
            display: inline-block;
            padding: 5px 1rem;
            box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.1);
            margin: 5px;
            background-color: #fff;
            svg {
              margin-left: 3px;
              &:hover {
                color: red;
              }
            }
            &:hover:not(.selected) {
              box-shadow: 0 0 0 1px #000;
            }
          }
          .selected {
            box-shadow: 0 0 0 1px $color-primary;
          }
        }
      }
    }
  }
  .map-view {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.f-upload {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  // align-items: center;
  img{
    object-fit: contain;
    width: 100%;
    height: 20rem;
  }
  &-box {
    width: 10rem;
    height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.2);
    &:hover{
      background-color: rgba($color: #000000, $alpha: 0.01);
    }
    svg{
      font-size: 2rem;
      margin-bottom: 5px;
    }
  }
}
